import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
    .use(initReactI18next)
    .init({
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                // Landing Page
                homeHeaderTitle: 'Welcome to the Ideation Portal',
                homeHeaderSubtitle: 'Your digital space for collecting, sharing, and exploring ideas',
                homeHeaderCard1Title: 'Idea Pool',
                homeHeaderCard2Title: 'Next Hackathon: ',
                homeHeaderCard3Title: 'Last Hackathon: ',
                homeHeaderCard1Subtitle: 'Discover the Idea Pool, our space where you can share your ideas.',
                homeHeaderCard2Subtitle: 'Find out more about our upcoming hackathon.',
                homeHeaderCard3Subtitle: 'Find out more about our previous hackathon.',
                homeHeaderCard1ActionText: 'Explore the Idea Pool',
                homeHeaderCard2ActionText: 'Join our Hackathons',
                homeHeaderCard3ActionText: 'View our Archive',
                homeQATitle: 'Frequently Asked Questions',
                // Idea Pool Page
                // Hackathons Page
                // Archive Page
            },
        },
        de: {
            translation: {
                // Landing Page
                homeHeaderTitle: 'Willkommen im Ideation Portal',
                homeHeaderSubtitle: 'Der Ort zum Sammeln, Teilen und Erkunden von Ideen',
                homeHeaderCard1Title: 'Ideen Pool',
                homeHeaderCard2Title: 'Nächster Hackathon: ',
                homeHeaderCard3Title: 'Letzter Hackathon: ',
                homeHeaderCard1Subtitle: 'Entdecken Sie den Ideenpool, unseren Raum, in dem Sie Ihre Ideen teilen können.',
                homeHeaderCard2Subtitle: 'Erfahren Sie mehr über unseren bevorstehenden Hackathon.',
                homeHeaderCard3Subtitle: 'Erfahren Sie mehr über unseren vorherigen Hackathon.',
                homeHeaderCard1ActionText: 'Zum Ideenpool',
                homeHeaderCard2ActionText: 'Zum Hackathon',
                homeHeaderCard3ActionText: 'Zum Archiv',
                homeQATitle: 'Häufig gestellte Fragen',
                // Idea Pool Page
                // Hackathons Page
                // Archive Page
            },
        },
    }
})

export default i18n
