import React from 'react'
import { Card, Container, SimpleGrid, Text, Title } from '@mantine/core'
import { useAppSelector } from '../hooks'
import { qAndAListEN, qAndAListDE } from '../common/HomeQandAContent'
import HeroHeader from '../components/HeroHeader'
import { styles } from '../common/styles'
import { useTranslation } from 'react-i18next'

function Home() {
  const lastHackathon = useAppSelector(
    (state) => state.hackathons.lastHackathon
  )
  const nextHackathon = useAppSelector(
    (state) => state.hackathons.nextHackathon
  )
  const { classes } = styles()
  const { i18n, t } = useTranslation()

  return (
    <>
      <HeroHeader nextHackathon={nextHackathon} lastHackathon={lastHackathon} />

      <Container fluid mb={150} mt={50}>
        <Title align={'center'} mb={20} order={2}>
          {t('homeQATitle')}
        </Title>
        <SimpleGrid cols={2} pt={20}>
          { i18n.language === 'de' ? (
              qAndAListDE.map((qAndA, index) => (
                  <Card
                      withBorder
                      shadow='sm'
                      p='lg'
                      key={index}
                      className={classes.card}
                  >
                    <Text weight={800} pb={10}>
                      {qAndA.question}
                    </Text>
                    <Text size='sm' style={{ lineHeight: 1.5 }}>
                      {qAndA.answer}
                    </Text>
                  </Card>
              ))
          ) : (
              qAndAListEN.map((qAndA, index) => (
                  <Card
                      withBorder
                      shadow='sm'
                      p='lg'
                      key={index}
                      className={classes.card}
                  >
                    <Text weight={800} pb={10}>
                      {qAndA.question}
                    </Text>
                    <Text size='sm' style={{ lineHeight: 1.5 }}>
                      {qAndA.answer}
                    </Text>
                  </Card>
              ))
          )}
        </SimpleGrid>
      </Container>
    </>
  )
}

export default Home
