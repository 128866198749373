import React, { useContext, useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { ActionIcon, Tooltip } from '@mantine/core'
import { showNotification, updateNotification } from '@mantine/notifications'
import { IconBookmark, IconBookmarkFilled } from '@tabler/icons-react'
import { CustomCheckIcon, CustomXIcon } from '../NotificationIcons'
import { UserContext } from '../../pages/Layout'
import { HackathonParticipantContext } from '../../pages/AllIdeas'
import { Idea } from '../../common/types'
import { createIdeaBookmarkParticipant, removeIdeaBookmarkParticipant } from '../../actions/ParticipantActions'
import { getIdeaDetails } from '../../actions/IdeaActions'

type IProps = {
  idea: Idea
  onSuccess?: () => void
}

export function BookmarkButton(props: IProps) {
  const user = useContext(UserContext)
  const hackathonParticipantId = useContext(HackathonParticipantContext)
  const { idea, onSuccess } = props
  const { instance } = useMsal()

  const [bookmarkChecked, setBookmarkChecked] = useState(false)
  const [loader, setLoader] = useState(false)
  const [ideaData, setIdeaData] = useState(idea)
  const [participantInfo, setParticipantInfo] = useState({
    userId: '',
    participantId: ''
  })

  useEffect(() => {
    if (findBookmark()) setBookmarkChecked(true)
    else setBookmarkChecked(false)
  }, [ideaData])

  useEffect(() => {
    if (user) {
      setParticipantInfo({
        userId: user.id,
        participantId: hackathonParticipantId
      })
    }
  }, [user, hackathonParticipantId])

  const findBookmark = () => {
    if (ideaData && ideaData.bookmarks && user) {
      return ideaData.bookmarks.some(bookmark => bookmark.user.id === user.id)
    }
    return false
  }

  const handleBookmarkAction = async () => {
    if (bookmarkChecked) {
      await removeBookmark()
    } else {
      await addBookmark()
    }
  }

  const removeBookmark = async () => {
    if (!participantInfo.participantId) {
      showNotification({
        id: 'participant-load',
        color: 'red',
        title: 'Not participating in hackathon',
        message: 'You must join the hackathon first to bookmark.',
        icon: <CustomXIcon />,
        autoClose: 5000
      })
      return
    }

    showNotification({
      id: 'participant-load',
      loading: true,
      title: `Removing bookmark from: "${idea.title}"`,
      autoClose: false,
      message: undefined,
      disallowClose: false
    })

    try {
      await removeIdeaBookmarkParticipant(instance, ideaData.id, participantInfo.participantId)
      setBookmarkChecked(false)
      updateNotification({
        id: 'participant-load',
        color: 'teal',
        message: undefined,
        title: `Bookmark removed from: "${ideaData.title}"`,
        icon: <CustomCheckIcon />,
        autoClose: 5000
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      console.error('Failed to remove bookmark:', error)
      updateNotification({
        id: 'participant-load',
        color: 'red',
        message: undefined,
        title: 'Failed to remove bookmark',
        icon: <CustomXIcon />,
        autoClose: 5000
      })
    } finally {
      setLoader(false)
    }
  }

  const addBookmark = async () => {
    if (!participantInfo.participantId) {
      showNotification({
        id: 'participant-load',
        color: 'red',
        title: 'Not participating in hackathon',
        message: 'You must join the hackathon first to bookmark.',
        icon: <CustomXIcon />,
        autoClose: 5000
      })
      return
    }

    showNotification({
      id: 'participant-load',
      loading: true,
      title: `Adding bookmark to: "${ideaData.title}"`,
      autoClose: false,
      message: undefined,
      disallowClose: false
    })

    try {
      await createIdeaBookmarkParticipant(instance, ideaData.id, participantInfo.participantId)
      setBookmarkChecked(true)
      updateNotification({
        id: 'participant-load',
        color: 'teal',
        message: undefined,
        title: `Bookmark added to: "${ideaData.title}"`,
        icon: <CustomCheckIcon />,
        autoClose: 5000
      })
      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      console.error('Failed to add bookmark:', error)
      updateNotification({
        id: 'participant-load',
        color: 'red',
        message: undefined,
        title: 'Failed to add bookmark',
        icon: <CustomXIcon />,
        autoClose: 5000
      })
    } finally {
      setLoader(false)
    }
  }

  return (
    <ActionIcon
      variant='transparent'
      onClick={handleBookmarkAction}
      color='orange.7'
    >
      <Tooltip
        withArrow
        transition='fade'
        transitionDuration={200}
        color='gray'
        label={!bookmarkChecked ? 'I\'m interested!' : 'I\'m not interested anymore'}
      >
        {bookmarkChecked ? <IconBookmarkFilled size={50} /> : <IconBookmark size={50} />}
      </Tooltip>
    </ActionIcon>
  )
}