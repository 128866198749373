import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Button, Container, Text, Title, Grid, createStyles, Card, Overlay, Badge,
} from '@mantine/core'
import { Idea } from '../common/types'
import { ArrowLeft } from 'tabler-icons-react'
import { useFullscreen } from '@mantine/hooks'
import { CHRISTMAS_BACKGROUND } from '../common/constants'

type CategoryMap = { [key: string]: Idea[] }
type CategoryVisibilityState = {
    [categoryId: string]: boolean;
}

export const styles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 15,
        paddingBottom: 15,
        margin: 5,
        backgroundColor:
            theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[1],
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

        '@media (max-width: 755px)': {
            paddingTop: 10,
            paddingBottom: 5,
        },
        border: '1px solid black',
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    title: {
        textAlign: 'center',
        fontWeight: 800,
        fontSize: 34,
        letterSpacing: '-0.02em',
        color: theme.colorScheme === 'light' ? theme.white : theme.black,
        textShadow: `
        -1px -1px 0px rgba(0, 0, 0, 0.7),
         1px -1px 0px rgba(0, 0, 0, 0.7),
        -1px  1px 0px rgba(0, 0, 0, 0.7),
         1px  1px 0px rgba(0, 0, 0, 0.7)`,
        marginBottom: theme.spacing.md,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        '@media (max-width: 520px)': {
            fontSize: 28,
        },
    },

    button: {
        backgroundColor: theme.colors.blue[6],
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: theme.colors.blue[7],
        },
    },

    card: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        withBorder: true,
        border: '1px solid black',
        shadow: 'md',
        padding: 'lg',
        marginBottom: '5px',
        marginTop: '5px',
    },

    fullscreen: {
        backgroundColor:
            theme.colorScheme === 'dark'
                ? theme.colors.dark[7]
                : theme.white,
        height: '100vh',
        width: '100vw',
        color: theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.white,
    },
}))

const allPlacesStyle = {
    border: '1px solid #FFFFFF',
    borderRadius: '10px',
    padding: '10px',
    margin: '10px 0',
}

const firstPlaceStyle = {
        backgroundColor: '#FFDF00', // Golden background for the 1st place
        ...allPlacesStyle,
    }

const secondPlaceStyle = {
        backgroundColor: '#969494', // Silver background for the 2nd place
        ...allPlacesStyle,
}

const thirdPlaceStyle = {
        backgroundColor: '#b3772d', // Bronze background for the 3rd place
        ...allPlacesStyle,
}

export default function WinnerPage() {
    const { classes } = styles()
    const [ideas, setIdeas] = useState<Idea[]>([])
    const [categories, setCategories] = useState<CategoryMap>({})
    const [CategoryVisibility, setCategoryVisibility] = useState<CategoryVisibilityState>({})
    const [OverallVisibility, setOverallVisibility] = useState(true)
    const { ref, toggle, fullscreen } = useFullscreen()

    const getPlaceStyle = (index: number, only1: boolean) => {
        if (index === 0) return firstPlaceStyle
        if (index === 1 && !only1) return secondPlaceStyle
        if (index === 2 && !only1) return thirdPlaceStyle
        return {}
    }

    const toggleOverallVisibility = () => {
        setOverallVisibility((v) => !v)
    }

    const toggleCategoryVisibility = (categoryId: string) => {
        setCategoryVisibility((prevState: CategoryVisibilityState) => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }))
    }

    const renderIdeaList = (ideasList: Idea[], only1: boolean) =>
        ideasList.map((idea, index) => (
            <Container key={idea.id} fluid style={getPlaceStyle(index, only1)}>
                <WinnerIdeaCard idea={idea} index={index} only1={only1}/>
            </Container>
        ))

    const renderCategoryGrid = () => (
        <Grid>
            {Object.keys(categories).map(categoryId => {
                const categoryIdeas = categories[categoryId]
                return (
                    <Grid.Col span={4} key={categoryId} onClick={() => toggleCategoryVisibility(categoryId)}>
                        <Container className={classes.wrapper}>
                            <Text align={'center'} mb={20} weight={700} size={18}>
                                {categoryIdeas[0]?.category?.title || 'No Category'}
                            </Text>
                            <div style={{ position: 'relative' }}>
                                {CategoryVisibility[categoryId] && <Overlay color='#000' opacity={1} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />}
                                {renderIdeaList(categoryIdeas, true)}
                            </div>
                        </Container>
                    </Grid.Col>
                )
            })}
        </Grid>
    )

    const renderIdeaGrid = () => (
        <Container className={classes.wrapper} onClick={toggleOverallVisibility}>
            <Title align={'center'} mb={20} weight={700} size={18}>
                The best of the best
            </Title>
            <div style={{ position: 'relative' }}>
                {OverallVisibility && <Overlay color='#000' opacity={1} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />}
                {renderIdeaList(ideas, false)}
            </div>
        </Container>
    )

    const WinnerIdeaCard = ({ idea, index, only1 }: { idea: Idea, index: number, only1: boolean }) => {
        return (
            <Card className={classes.card}>
                <Text mb={0} weight={600}>
                    {index+1+'. '}{idea.title}
                </Text>
                <Text mb={5}>{idea.voters?.length} votes</Text>
                {( index < 3 && !only1 || index < 1 && only1 ) && idea.participants?.map(participant => (
                    <Badge key={participant.user.id} size={'xs'}>
                        {participant.user.firstName} {participant.user.lastName}
                    </Badge>
                ))}
            </Card>
        )
    }

    useEffect(() => {
        const ideaLocalStorage = () => {
            const ideaStorage = localStorage.getItem('ideas')
            if (ideaStorage) {
                const parsedIdeas = JSON.parse(ideaStorage)
                setIdeas(parsedIdeas.sort((a: { voters: string | any[] }, b: { voters: string | any[] }) => b.voters.length - a.voters.length))

                const categoryGroups = parsedIdeas.reduce((acc: { [x: string]: any[] }, idea: { category: { id: string | number } }) => {
                    acc[idea.category.id] = acc[idea.category.id] || []
                    acc[idea.category.id].push(idea)
                    return acc
                }, {})

                for (const categoryId in categoryGroups) {
                    categoryGroups[categoryId].sort((a: { voters: string | any[] }, b: { voters: string | any[] }) => b.voters.length - a.voters.length)
                }

                setCategories(categoryGroups)

                const initialVisibility: CategoryVisibilityState = {}
                for (const categoryId in categoryGroups) {
                    initialVisibility[categoryId] = true
                }
                setCategoryVisibility(initialVisibility)
            }
        }

        ideaLocalStorage()
    }, [])

    return (
        <>
            <Button component={Link} to='/admin' leftIcon={<ArrowLeft />} className={classes.button}>
                Back to Admin
            </Button>
            <Title my={20} order={1} className={classes.title}>
                AND THE WINNER IS...
            </Title>
            <Button onClick={toggle} className={classes.button}>
                Fullscreen
            </Button>
            <div ref={ref} style={{ overflowY: 'auto', height: '100%', backgroundImage: `url(${CHRISTMAS_BACKGROUND})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center' }} className={classes.fullscreen}>
                {fullscreen && (
                    <div>
                        <Title my={20} order={1} className={classes.title}>
                            AND THE WINNER IS...
                        </Title>
                        <Grid>
                            <Grid.Col span={5}>
                                <Title className={classes.title} order={2} align={'center'}>
                                    Overall Winner:
                                </Title>
                                <Container>
                                    {renderIdeaGrid()}
                                </Container>
                            </Grid.Col>

                            <Grid.Col span={7}>
                                <Title className={classes.title} order={2} align={'center'}>
                                    Winner per Category:
                                </Title>
                                {renderCategoryGrid()}
                            </Grid.Col>
                        </Grid>
                    </div>
                )}
            </div>
        </>
    )
}
