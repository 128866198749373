export const qAndAListEN = [
  {
    question: 'What is a Hackathon?',
    answer:
      'A hackathon is a design sprint-like event, in which often computer programmers and others involved in software development, including graphic designers, interface designers, product managers, project managers, domain experts, and others collaborate intensively on software projects.',
    imageText: 'Test Image',
  },
  {
    question: 'How to use this site?',
    answer:
      'In the navigation of the Hackathon Management System (HMS) you can switch between different pages, in which you can participate in specific hackathons and view or submit your own ideas.',
  },
  {
    question: 'Reasons to Participate?',
    answer:
      'Maybe you have a great idea that you have wanted to work on for years? Use the chance to build a prototype! Maybe you want to learn or test a new framework? Then this is your save space! You have a solution for an existing problem? Come and find allies for it.',
  },
  {
    question: 'How to participate?',
    answer:
      'If you want to participate in a Hackathon, navigate to the "All Ideas" page, select the Hackathon and click on the participate button.',
  },
  {
    question: 'How to find Ideas?',
    answer:
      'Under "All Ideas" you can select a Hackathon to see all submitted ideas. Also, you have the opportunity to search for specific idea titles. The idea cards itself are expandable, so you can see all relevant information. If you like an Idea and want to participate, you will find a participate button on the bottom of every expanded idea card.',
  },
  {
    question: 'How to submit Ideas?',
    answer:
      'If you are already registered for a hackathon and want to submit your own ideas, navigate to the "My Ideas" page. On this page you can select the Hackathon you want to submit a new idea. To edit or delete an already submitted idea, load the hackathon to see your ideas, then expand the Idea Card you want and click on the Edit or Delete button on the bottom.',
  },
  // {
  //   question: 'How to find old Hackathons?',
  //   answer:
  //     'If you are interested in Past Hackathons or want to find an old idea that you remember, you can use the Archive. In the Archive you will find a selection of all past hackathons with their submitted ideas.',
  // },
  /* {
    question: 'How the Voting and the Hackathon itself will work?',
    answer:
      '---add explanation about the voting system--- ---add space for specific explanation?---',
  },*/
]

export const qAndAListDE = [
  {
    question: 'Was ist ein Hackathon?',
    answer:
      'Ein Hackathon ist ein Design-Sprint-ähnliches Event, bei dem oft Computerprogrammierer und andere, die an der Softwareentwicklung beteiligt sind, einschließlich Grafikdesignern, Interface-Designern, Produktmanagern, Projektmanagern, Domänenexperten und anderen, intensiv an Softwareprojekten zusammenarbeiten.',
    imageText: 'Test Image',
  },
  {
    question: 'Wie benutze ich diese Seite?',
    answer:
      'In der Navigation des Hackathon Management Systems (HMS) können Sie zwischen verschiedenen Seiten wechseln, in denen Sie an spezifischen Hackathons teilnehmen und Ihre eigenen Ideen einsehen oder einreichen können.',
    },
    {
    question: 'Gründe zur Teilnahme?',
    answer:
      'Vielleicht haben Sie eine großartige Idee, an der Sie seit Jahren arbeiten wollten? Nutzen Sie die Chance, einen Prototyp zu bauen! Vielleicht möchten Sie ein neues Framework lernen oder testen? Dann ist dies Ihr sicherer Raum! Sie haben eine Lösung für ein bestehendes Problem? Kommen Sie und finden Sie Verbündete dafür.',
    },
    {
    question: 'Wie kann ich teilnehmen?',
    answer:
      'Wenn Sie an einem Hackathon teilnehmen möchten, navigieren Sie zur Seite "Alle Ideen", wählen Sie den Hackathon aus und klicken Sie auf die Schaltfläche "Teilnehmen".',
    },
    {
    question: 'Wie finde ich Ideen?',
    answer:
      'Unter "Alle Ideen" können Sie einen Hackathon auswählen, um alle eingereichten Ideen zu sehen. Außerdem haben Sie die Möglichkeit, nach bestimmten Ideentiteln zu suchen. Die Ideenkarten selbst sind erweiterbar, sodass Sie alle relevanten Informationen sehen können. Wenn Ihnen eine Idee gefällt und Sie teilnehmen möchten, finden Sie am unteren Rand jeder erweiterten Ideenkarte eine Schaltfläche "Teilnehmen".',
    },
    {
    question: 'Wie reiche ich Ideen ein?',
    answer:
      'Wenn Sie bereits für einen Hackathon registriert sind und Ihre eigenen Ideen einreichen möchten, navigieren Sie zur Seite "Meine Ideen". Auf dieser Seite können Sie den Hackathon auswählen, für den Sie eine neue Idee einreichen möchten. Um eine bereits eingereichte Idee zu bearbeiten oder zu löschen, laden Sie den Hackathon, um Ihre Ideen zu sehen, erweitern Sie dann die Ideenkarte, die Sie möchten, und klicken Sie unten auf die Schaltfläche "Bearbeiten" oder "Löschen".',
    },
    // {
    //   question: 'Wie finde ich alte Hackathons?',
    //   answer:
    //     'Wenn Sie an vergangenen Hackathons interessiert sind oder eine alte Idee finden möchten, an die Sie sich erinnern, können Sie das Archiv verwenden. Im Archiv finden Sie eine Auswahl aller vergangenen Hackathons mit ihren eingereichten Ideen.',
    // },
    /* {
      question: 'Wie funktioniert das Voting und der Hackathon selbst?',
      answer:
        '---add explanation about the voting system--- ---add space for specific explanation?---',
    },*/
]
