import {
  Button,
  Card,
  Container,
  SimpleGrid,
  Text,
  Title,
  useMantineColorScheme,
} from '@mantine/core'
import React, { useMemo } from 'react'
import { HackathonSerializable } from '../common/types'
import { Link } from 'react-router-dom'
import { MAX_DATE, MIN_DATE } from '../common/constants'
import { heroHeaderStyles } from '../common/styles'
import { useTranslation } from 'react-i18next'

const InfoCard = (props: {
  title: string
  description: string
  link: string
  buttonText: string
}) => {
  const theme = useMantineColorScheme()
  const { classes } = heroHeaderStyles()
  return (
      <Card withBorder shadow='md' p='lg'>
        <div className={classes.cardContent}>
          <div>
            <Title align={'center'} order={3}>
              {props.title}
            </Title>
            <Text align={'center'}>{props.description}</Text>
          </div>
          <div className={classes.buttonArea}>
            <Button
                size='sm'
                component={Link}
                to={props.link}
                color={theme.colorScheme === 'dark' ? 'orange' : 'blue'}
            >
              {props.buttonText}
            </Button>
          </div>
        </div>
      </Card>
  )
}

const HackathonCard = (props: {
  title: string
  hackathonTitle: string
  subtitle: string
  date: Date
  actionLink: string
  actionText: string
  upcoming?: boolean
}) => {
  const theme = useMantineColorScheme()
  const { classes } = heroHeaderStyles()

  return (
      <Card withBorder shadow='md' p='lg'>
        <div className={classes.cardContent}>
          <div>
            <Title align={'center'} order={3}>
              {props.title}
            </Title>
            <Title align={'center'} order={3} className={classes.highlight}>
              {props.hackathonTitle}
            </Title>
            <Text align={'center'} size='sm'>
              {props.upcoming
                  ? props.date > MAX_DATE
                      ? 'Start: TBA'
                      : 'Start: ' +
                      props.date.toLocaleDateString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      })
                  : props.date < MIN_DATE
                      ? 'Unknown'
                      : 'Ended: ' +
                      props.date.toLocaleDateString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      })}
            </Text>
            <Text align={'center'}>{props.subtitle}</Text>
          </div>
          <div className={classes.buttonArea}>
            <Button
                size='sm'
                component={Link}
                to={props.actionLink}
                color={theme.colorScheme === 'dark' ? 'orange' : 'blue'}
            >
              {props.actionText}
            </Button>
          </div>
        </div>
      </Card>
  )
}

const HeroHeader = (props: {
  nextHackathon: HackathonSerializable
  lastHackathon: HackathonSerializable
}) => {
  const { classes } = heroHeaderStyles()
  const { t } = useTranslation()

  // Using useMemo for performance
  const today = useMemo(() => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    return date
  }, [])

  return (
      <Container className={classes.wrapper} size={1400}>
        <div>
          <Title className={classes.title} order={2} align={'center'}>
            {t('homeHeaderTitle')}
          </Title>
          <Text align={'center'} mb={20}>
            {t('homeHeaderSubtitle')}
          </Text>
        </div>

        <SimpleGrid cols={3} pt={30} spacing='md'>
          <InfoCard
              title={t('homeHeaderCard1Title')}
              description={t('homeHeaderCard1Subtitle')}
              link='/idea-pool'
              buttonText={t('homeHeaderCard1ActionText')}
          />

          {props.nextHackathon &&
          new Date(props.nextHackathon.startDate) > today ? (
              <HackathonCard
                  title={t('homeHeaderCard2Title')}
                  hackathonTitle={props.nextHackathon.title}
                  subtitle={t('homeHeaderCard2Subtitle')}
                  date={new Date(props.nextHackathon.startDate)}
                  actionLink='/hackathons'
                  actionText={t('homeHeaderCard2ActionText')}
                  upcoming={true}
              />
          ) : (
              <div>Something went wrong...</div>
          )}

          {props.lastHackathon &&
          new Date(props.lastHackathon.endDate) > MIN_DATE ? (
              <HackathonCard
                  title={t('homeHeaderCard3Title')}
                  hackathonTitle={props.lastHackathon.title}
                  subtitle={t('homeHeaderCard3Subtitle')}
                  date={new Date(props.lastHackathon.endDate)}
                  actionLink='/archive'
                  actionText={t('homeHeaderCard3ActionText')}
              />
          ) : (
              <div>Something went wrong...</div>
          )}
        </SimpleGrid>
      </Container>
  )
}

export default HeroHeader
