import React from 'react'
import { Hackathon, Idea, IdeaCardType, SortOption } from '../../common/types'
import { Grid } from '@mantine/core'
import IdeaDetails from '../card-details/IdeaDetails'

type IProps = {
  ideas: Idea[]
  isLoading: boolean
  columnSize: number
  type: IdeaCardType
  onSuccess?: () => void
  isHackathonStarted?: boolean
  hackathon: Hackathon
  sortOption?: string
}

export default function IdeaCardList(props: IProps) {
  const {
    ideas,
    columnSize,
    type,
    isLoading,
    onSuccess,
    isHackathonStarted,
    hackathon,
    sortOption
  } = props

  const sortedIdeas = ideas.sort((a, b) => {
    switch (sortOption) {
      case SortOption.CreationDateAsc:
        return new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
      case SortOption.CreationDateDesc:
        return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      case SortOption.ParticipantsAsc:
        return (a.participants?.length || 0) - (b.participants?.length || 0)
      case SortOption.ParticipantsDesc:
        return (b.participants?.length || 0) - (a.participants?.length || 0)
      case SortOption.BookmarksAsc:
        return (a.bookmarks?.length || 0) - (b.bookmarks?.length || 0)
      case SortOption.BookmarksDesc:
        return (b.bookmarks?.length || 0) - (a.bookmarks?.length || 0)
      default:
        // Default to sorting by creationDate descending if no valid sortOption is provided
        return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
    }
  })

  const IdeasList = sortedIdeas.map((idea, id) => {
    return (
      <Grid.Col key={idea.id} sm={columnSize} lg={columnSize}>
        <div style={{ padding: 10 }}>
          <IdeaDetails
            idea={idea}
            type={type}
            isLoading={isLoading}
            onSuccess={onSuccess}
            isHackathonStarted={isHackathonStarted}
            hackathon={hackathon}
          />
        </div>
      </Grid.Col>
    )
  })

  return (
    <>
      <Grid gutter={'lg'} justify={'center'}>
        <Grid gutter={'lg'} style={{ minWidth: '100%' }}>
          {IdeasList}
        </Grid>
      </Grid>
    </>
  )
}
