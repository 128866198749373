import { Idea } from '../common/types'
import {
  Button,
  Center,
  Collapse,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import React, { createContext, useEffect, useState } from 'react'
import VideoDropzone from './VideoDropzone'
import {
  checkIfVideoExists,
  getPresignedUrl,
  uploadVideoToS3,
} from '../actions/IdeaActions'
import { useMsal } from '@azure/msal-react'
import { showNotification } from '@mantine/notifications'
import { useToggle } from '@mantine/hooks'
import { Upload } from 'tabler-icons-react'
import { CustomCheckIcon, CustomXIcon } from './NotificationIcons'

type IProps = {
  idea: Idea
}
export const UploadLoadingContext = createContext(false)
const VIDEO_URL = process.env.REACT_APP_PRESENTATION_MEDIA_URL

export default function FinalVideoUploadModal({ idea }: IProps) {
  const { instance } = useMsal()
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')
  const [previousOpen, toggle] = useToggle([false, true])

  const checkAsyncForVideo = async () => {
    const videoUrl = `${VIDEO_URL}/${idea.id}.mp4`
    const res = await checkIfVideoExists(videoUrl)
    if (res?.ok) setVideoUrl(videoUrl)
  }

  useEffect(() => {
    checkAsyncForVideo()
  }, [])

  const uploadVideo = async (file: File) => {
    const reader = new FileReader()
    reader.onabort = () => {
      console.log('file reading was aborted')
      setLoading(false)
    }
    reader.onerror = () => {
      console.log('file reading has failed')
      setLoading(false)
    }
    reader.onload = async () => {
      const media = new Blob([reader.result as ArrayBuffer])
      const video = document.createElement('video')
      video.src = URL.createObjectURL(media)
      video.onloadedmetadata = async () => {
        const videoDuration = video.duration
        console.log('video duration: ', video.duration)

        // Check if the video duration exceeds 180 seconds
        if (videoDuration > 185) {
          showNotification({
            title: 'Video too long',
            message: 'Your video exceeds the maximum length of 180 seconds',
            color: 'red',
            icon: <CustomXIcon />,
            autoClose: 5000,
          })
          setLoading(false)
          return // Exit the function without uploading the video
        }

        const binaryStr = reader.result
        const res = await getPresignedUrl(instance, idea.id, 'upload')
        const uploadRes = await uploadVideoToS3(res.signedUrl, idea.id, binaryStr)
        setLoading(false)

        if (uploadRes?.status === 200) {
          showNotification({
            title: 'Video uploaded',
            message: 'Your video has been uploaded',
            color: 'green',
            icon: <CustomCheckIcon />,
            autoClose: 5000,
          })
          setTimeout(() => {
            checkAsyncForVideo()
          }, 1000)
        } else {
          showNotification({
            title: 'Video upload failed',
            message: 'Your video has not been uploaded',
            color: 'red',
            icon: <CustomXIcon />,
            autoClose: 5000,
          })
        }
      }
    }
    setLoading(true)
    reader.readAsArrayBuffer(file)
  }

  return (
    <>
      <UploadLoadingContext.Provider value={loading}>
        <Modal opened={opened} onClose={() => setOpened(false)} size={800}>
          <Title align={'center'} mb={20}>
            Video upload
          </Title>
          <Text
            align={'center'}
            style={{ maxWidth: 700, margin: 'auto', marginBottom: 50 }}
          >
            Here you can upload your final presentation video. If you want to
            update a previous one for this idea just upload it again. This will
            overwrite the previous one.
          </Text>
          <VideoDropzone uploadVideo={uploadVideo} />

          {videoUrl && (
            <>
              <Center>
                <Button onClick={() => toggle()} my={20}>
                  Show previously uploaded video
                </Button>
              </Center>

              <Collapse in={previousOpen}>
                <Stack>
                  <video width='600' controls style={{ margin: 'auto' }}>
                    <source src={videoUrl} type='video/mp4' />
                    Your browser does not support HTML video.
                  </video>
                </Stack>
              </Collapse>
            </>
          )}
        </Modal>

        <Button size='xs' onClick={() => setOpened(true)} color={'green'}>
          <Upload size={20} />
        </Button>
      </UploadLoadingContext.Provider>
    </>
  )
}
