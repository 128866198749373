import React, { useState } from 'react'
import { SortOption } from '../common/types'
import { Select } from '@mantine/core'
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react'

type IProps = {
  onSelectedSorting: (sortOption: SortOption) => void;
};

const SortingSelector: React.FC<IProps> = (props) => {
  const [selectedSorting, setSelectedSorting] = useState<SortOption>(SortOption.CreationDateDesc)

  const handleSortingChange = (value: SortOption) => {
    setSelectedSorting(value)
    props.onSelectedSorting(value)
  }

  const getIconForSorting = (sortingOption: SortOption) => {
    switch (sortingOption) {
      case SortOption.CreationDateAsc:
      case SortOption.ParticipantsAsc:
      case SortOption.BookmarksAsc:
        return <IconSortAscending />
      case SortOption.BookmarksDesc:
      case SortOption.CreationDateDesc:
      case SortOption.ParticipantsDesc:
          return <IconSortDescending />
    default:
        return <IconSortAscending />
    }
  }

  return (
    <Select
      style={{ maxWidth: 200 }}
      value={selectedSorting}
      icon={getIconForSorting(selectedSorting)}
      onChange={handleSortingChange}
      data={[
        { value: SortOption.CreationDateAsc, label: 'Creation Date (Asc)' },
        { value: SortOption.CreationDateDesc, label: 'Creation Date (Desc)' },
        { value: SortOption.ParticipantsAsc, label: 'Participants (Asc)' },
        { value: SortOption.ParticipantsDesc, label: 'Participants (Desc)' },
        { value: SortOption.BookmarksAsc, label: 'Bookmarks (Asc)' },
        { value: SortOption.BookmarksDesc, label: 'Bookmarks (Desc)' },
      ]}
      aria-label='Select sorting option'
    />
  )
}

export default SortingSelector