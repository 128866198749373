import { Alert, Badge, Center, Container, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { Idea } from '../common/types'
import { checkIfVideoExists } from '../actions/IdeaActions'
import ReactPlayer from 'react-player'
import { AlertCircle } from 'tabler-icons-react'
import { orange3 } from '../common/colors'

type IProps = {
  idea: Idea
  classes: any
}

const VIDEO_URL = process.env.REACT_APP_PRESENTATION_MEDIA_URL

export default function FinalPresentationsSlide({ idea, classes }: IProps) {
  const [videoUrl, setVideoUrl] = useState('')

  useEffect(() => {
    async function checkAsyncForVideo() {
      // TODO change URL
      const videoUrl = `${VIDEO_URL}/${idea.id}.mp4`
      const res = await checkIfVideoExists(videoUrl)
      if (res?.ok) setVideoUrl(videoUrl)
    }

    checkAsyncForVideo()
  }, [])

  return (
    <Container key={idea.id} className={classes.idea} fluid>
      <Badge
        fullWidth={false}
        color={'gray'}
        mb={5}
        size={'md'}
        variant={'outline'}
      >
        {idea.category?.title}
      </Badge>
      <Title className={classes.title} mb={5}>
        {idea.title}
      </Title>
        <Title
            mb={5}
            // className={classes.name}
            color={orange3}
            size={'sm'}
        >{`by ${idea.owner?.firstName} ${idea.owner?.lastName}`}
        </Title>
        {idea.participants?.map((participant) => {
            return (
                <Badge
                key={participant.id}
                fullWidth={false}
                color={'blue'}
                mr={10}
                size={'sm'}
                variant={'outline'}
                >
                    {participant.user.firstName} {participant.user.lastName}
                </Badge>
            )}
        )}
      <Container p={10} fluid>
        {videoUrl ? (
          <Center>
            <ReactPlayer url={videoUrl} controls width={'85%'} height={'85%'} />
          </Center>
        ) : (
          <Alert
            mt={20}
            icon={<AlertCircle size={16} />}
            title='No video provided!'
            color='red'
          >
            There is no video for this idea. The presenter needs to upload a
            video or present manually.
          </Alert>
        )}
      </Container>
    </Container>
  )
}
