import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ActionIcon,
  Badge,
  Button,
  Container,
  createStyles,
  Grid,
  Group,
  NumberInput,
  Text,
  Title,
} from '@mantine/core'
import { useFullscreen } from '@mantine/hooks'
import { Hackathon, Idea, IdeaCardType } from '../common/types'
import Carousel from 'nuka-carousel'
import { ArrowLeft, ArrowNarrowRight, PlayerPlay } from 'tabler-icons-react'
import { blue4, dark2, orange3, PAGE_BACKGROUND_DARK } from '../common/colors'
import PitchTimer from '../components/PitchTimer'

const useStyles = createStyles((_theme, _params, getRef) => ({
  controls: {
    ref: getRef('controls'),
    transition: 'opacity 150ms ease',
    opacity: 0,
  },

  root: {
    '&:hover': {
      [`& .${getRef('controls')}`]: {
        opacity: 1,
      },
    },
  },

  fullscreen: {
    backgroundColor: PAGE_BACKGROUND_DARK,
    height: '100vh',
    width: '100vw',
    color: 'white',
  },

  idea: {
    height: '100vh',
    padding: '150px 100px',
    overflow: 'auto'
  },

  title: {
    color: _theme.white,
    fontFamily: `Greycliff CF, ${_theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: 1200,
    fontSize: 58,

    [_theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      fontSize: 34,
      lineHeight: 1.15,
    },
  },

  name: {
    color: orange3,
    fontSize: 35,
  },

  description: {
    maxWidth: 1000,
  },

  text: {
    color: _theme.white,
    opacity: 0.75,
    fontSize: 24,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',

    [_theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },

  subTitle: {
    fontSize: 35,
    opacity: 1,
  },

  card: {
    borderRadius: 20,
    backgroundColor: blue4,
    padding: '20px 30px',
  },
}))

export default function PitchPresentations() {
  const { classes } = useStyles()
  const { ref, toggle, fullscreen } = useFullscreen()
  const [ideas, setIdeas] = useState<Idea[]>([])
  const [timerValue, setTimerValue] = useState({ minutes: 2, seconds: 0 })
  const [prePreviewLocation, setPrePreviewLocation] = useState('')

  useEffect(() => {
    const ideaLocalStorage = () => {
      const ideaStorage = localStorage.getItem('ideas')
      const prePreviewLocation = localStorage.getItem('prePreviewLocation')
      if (ideaStorage && prePreviewLocation) {
        return (
            setIdeas(JSON.parse(ideaStorage)),
            setPrePreviewLocation(JSON.parse(prePreviewLocation))
        )
      }
      return null
    }

    ideaLocalStorage()
  }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [prePreviewLocation])

  const ideaList = ideas?.map((idea) => {
    return (
        <Container key={idea.id} className={classes.idea} fluid>
          <Badge
              fullWidth={false}
              color={'gray'}
              size={'lg'}
              variant={'outline'}
          >
            {idea.category?.title}
          </Badge>
          <Title className={classes.title} mb={10}>
            {idea.title}
          </Title>
          <Title
              mb={10}
              order={2}
              className={classes.name}
          >{`by ${idea.owner?.firstName} ${idea.owner?.lastName}`}
          </Title>
            {idea.requiredSkills?.map((skill) => {
                return (
                    <Badge
                        key={skill.id}
                        fullWidth={false}
                        color={'blue'}
                        mr={10}
                        size={'md'}
                        variant={'outline'}
                    >
                        {skill.name}
                    </Badge>
                )}
                )}
          <Text className={`${classes.text} ${classes.description}`} mt={30}>
            {idea.description}
          </Text>
          <Grid grow justify={'center'} align={'center'} mt={100}>
            <Grid.Col span={5} pr={30} className={classes.card}>
              <Title order={3} className={`${classes.text} ${classes.subTitle}`}>
                🤔 Problem
              </Title>
              <Text className={classes.text}>{idea.problem}</Text>
            </Grid.Col>
            <Grid.Col span={2} px={50}>
              <ArrowNarrowRight size={'100%'} color={dark2} />
            </Grid.Col>
            <Grid.Col span={5} pl={30} className={classes.card}>
              <Title order={3} className={`${classes.text} ${classes.subTitle}`}>
                🚀 Goal
              </Title>
              <Text className={classes.text}>{idea.goal}</Text>
            </Grid.Col>
          </Grid>
        </Container>
    )
  })

  return (
      <>
        { ideas.length > 1 ? (
            <Button component={Link} to='/admin' leftIcon={<ArrowLeft />}>
              Admin
            </Button>
        ) :
            <Button component={Link} to={prePreviewLocation} leftIcon={<ArrowLeft />}>
              Back
            </Button>
        }

        <Title my={20} order={1}>
          Pitch presentation
        </Title>
        <Text size={'lg'} weight={600}>
          Set time per pitch
        </Text>
        <Group mb={40} mt={10}>
          <NumberInput
              defaultValue={timerValue.minutes}
              label={'Minutes'}
              size={'md'}
              min={0}
              max={59}
              required
              onChange={(val) => {
                setTimerValue({ ...timerValue, minutes: val || 0 })
              }}
          />
          <NumberInput
              defaultValue={timerValue.seconds}
              label={'Seconds'}
              size={'md'}
              min={0}
              max={59}
              required
              onChange={(val) => {
                setTimerValue({ ...timerValue, seconds: val || 0 })
              }}
          />
        </Group>

        <Text size={'lg'} weight={600}>Fullscreen: </Text>
        <ActionIcon
            color={'green'}
            size={100}
            variant={'filled'}
            onClick={toggle}
            mb={40}
        >
          <PlayerPlay size={40} />
        </ActionIcon>

        <div style={{ maxWidth: '100%', margin: '0 auto' }} className={classes.fullscreen}>
          <Carousel enableKeyboardControls>{ideaList}</Carousel>
        </div>

        <div ref={ref}>
          {fullscreen && (
              <div className={classes.fullscreen}>
                <PitchTimer
                    minutes={timerValue.minutes}
                    seconds={timerValue.seconds}
                />
                <Carousel enableKeyboardControls>{ideaList}</Carousel>
              </div>
          )}
        </div>
      </>
  )
}
